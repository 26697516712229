import { useQuery } from 'react-query'
import { useBookingContext } from './BookingContext'

export const GetBookingQueryKey = 'getBooking'

export const useGetBooking = (
  reservationGuid: string,
  enabled: boolean = true
) => {
  const { getBooking, setBooking } = useBookingContext()
  const { restaurantGuid } = window.TT_GLOBALS

  return useQuery(
    [GetBookingQueryKey, reservationGuid],
    () => getBooking(reservationGuid, restaurantGuid),
    {
      onSuccess: (data) => setBooking(data),
      enabled: Boolean(enabled && reservationGuid)
    }
  )
}
