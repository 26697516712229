import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import Cookies from 'js-cookie'

export const useGetRwgTokenAndMerchantId = (restaurantGuid: string) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const rwgToken = searchParams.get('rwg_token')
    const merchantId = restaurantGuid

    if (rwgToken && rwgToken?.length > 0) {
      // Always update the cookie with the new token data
      Cookies.set('rwgData', JSON.stringify({ rwgToken, merchantId }), {
        expires: 30,
        secure: true,
        sameSite: 'Strict',
        path: '/'
      })
    }
  }, [searchParams])
}
