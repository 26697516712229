import { DateTime } from 'luxon'

export function parseEpochOrIsoToUtc(dateTimeString: string): DateTime {
  if (/^\d+$/.test(dateTimeString)) {
    // If the dateTimeString is a number, determine if it's in seconds or milliseconds
    const epochTime = parseInt(dateTimeString, 10)
    if (dateTimeString.length === 10) {
      return DateTime.fromSeconds(epochTime).toUTC()
    } else if (dateTimeString.length === 13) {
      return DateTime.fromMillis(epochTime).toUTC()
    } else {
      return DateTime.invalid('Invalid epoch time format')
    }
  } else {
    // Otherwise, treat it as ISO format
    return DateTime.fromISO(dateTimeString).toUTC()
  }
}
