import { DateTime } from 'luxon'
import { SelectOption } from '../../../common/types'

export const findClosestFutureTime = (
  options: SelectOption[] | undefined,
  timeZone: string,
  now = DateTime.now()
) => {
  // If the passed in date is today, just use the current time
  const isToday = now
    .setZone(timeZone)
    .hasSame(DateTime.now().setZone(timeZone), 'day')
  const nowTime = isToday
    ? DateTime.now().setZone(timeZone)
    : now.setZone(timeZone)

  const currentTime = DateTime.fromISO(nowTime.toISO(), {
    zone: timeZone
  })

  const futureOptions = options?.filter((option) => {
    const optionDate = DateTime.fromISO(option.value.toString(), {
      zone: timeZone
    })
    return optionDate > currentTime
  })

  // If no valid options, return the next 30-minute increment
  if (!futureOptions || futureOptions.length === 0) {
    const nextInterval = currentTime
      .plus({ minutes: 30 - (currentTime.minute % 30) })
      .startOf('minute')
    return nextInterval
  }

  // Find the closest future time from the options
  const closestFuture = futureOptions.reduce((closest, current) => {
    const currentDateTime = DateTime.fromISO(current.value.toString(), {
      zone: timeZone
    })
    const closestDateTime = DateTime.fromISO(closest.value.toString(), {
      zone: timeZone
    })

    return currentDateTime.diff(currentTime).milliseconds <
      closestDateTime.diff(currentTime).milliseconds
      ? current
      : closest
  })

  const closestDateTime = DateTime.fromISO(closestFuture.value.toString(), {
    zone: timeZone
  })

  return closestDateTime
}
