import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  ArrowCircleRightIcon,
  DiningIcon,
  GuestIcon,
  ToastAccountIcon
} from '@toasttab/buffet-pui-icons'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useNavigate } from 'react-router'
import * as React from 'react'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import { LoginModal } from './LoginModal'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../utils/analyticsEvents'

type GuestAccountMenuProps = {
  openLoginModal: boolean
  setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>
  targetRoute: string | null
  setTargetRoute: React.Dispatch<React.SetStateAction<string | null>>
  restaurant: RestaurantInfo
}

export const GuestAccountMenu = ({
  openLoginModal,
  setOpenLoginModal,
  targetRoute,
  setTargetRoute,
  restaurant
}: GuestAccountMenuProps) => {
  const { shortUrl } = useRestaurantContext()
  const { logOut, isAuthenticated, setIsAuthenticated } = useAuth()
  const navigate = useNavigate()
  const { showSuccessSnackBar } = useSnackBar()

  return (
    <>
      <MenuDropdown
        placement='bottom-end'
        renderToggle={(props) => (
          <IconButton
            aria-label='Open menu'
            icon={
              <ToastAccountIcon
                className='text-gray-100'
                size={'sm'}
                accessibility='decorative'
              />
            }
            contained
            {...props}
          />
        )}
      >
        <ListItem
          testId='manage-profile-select'
          icon={<GuestIcon aria-label='Go to profile' />}
          onClick={() => {
            if (!isAuthenticated) {
              setOpenLoginModal(true)
              setTargetRoute(`/${shortUrl}/account/profile`)
            } else {
              navigate(`/${shortUrl}/account/profile`)
            }
          }}
          label='View account info'
          className='w-80'
        />
        <ListItem
          testId='manage-bookings-select'
          icon={<DiningIcon aria-label='Go to bookings' />}
          onClick={() => {
            if (!isAuthenticated) {
              setOpenLoginModal(true)
              setTargetRoute(`/${shortUrl}/account/bookings`)
            } else {
              navigate(`/${shortUrl}/account/bookings`)
            }
          }}
          label='Manage bookings'
          className='w-80'
        />
        {/* <ListItem
            icon={<CreditIcon aria-label='Go to payments'/>}
            onClick={() => {
              if (!isAuthenticated) {
                setOpenLoginModal(true)
                setTargetRoute(`/${shortUrl}/account/payments`)
              } else {
                navigate(`/${shortUrl}/account/payments`)
              }
            }}
            label="Manage your payments"
            className='pl-14 w-80'
          /> */}
        {isAuthenticated && (
          <ListItem
            testId='logout-select'
            icon={<ArrowCircleRightIcon aria-label='Log out' />}
            onClick={() => {
              logOut()
              setIsAuthenticated(false)
              showSuccessSnackBar('Logged out successfully')
              track(AnalyticsEvents.LOGOUT)
            }}
            label='Log out'
            className='w-80'
          />
        )}
      </MenuDropdown>
      <LoginModal
        onRequestClose={() => {
          setOpenLoginModal(false)
        }}
        isOpen={openLoginModal}
        setIsOpen={setOpenLoginModal}
        targetRoute={targetRoute}
        fromModifyButton={false}
        fromCancelButton={false}
        restaurant={restaurant}
      />
    </>
  )
}
