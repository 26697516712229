import React, { useCallback, useMemo, useState } from 'react'
import {
  formatServiceAreaGroups,
  ServiceAreaGroup
} from '../ReservationDetailEntryView/ServiceAreaGroup/ServiceAreaGroup'
import { useGetAvailabilities } from '../../api/availabilities/useGetAvailabilities'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { usePartySize } from '../../hooks/usePartySize'
import { formatAvailabilities } from '../../utils/formatAvailabilities'
import { Deposit } from '../../api/availabilities/getAvailabilities'
import { useRemoveParams } from '../../hooks/useRemoveParams'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { CreditCardFormProvider } from '../CreditCardForm/CreditCardFormProvider'
import { ReservationBookModal } from '../ReservationBookModal/ReservationBookModal'
import { useIntlProps } from 'banquet-runtime-modules'
import { DateTimeError } from '../DateTimeError'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useOpenBookingModalOnMount } from './useOpenBookingModalOnMount'
import { ModifySearchButton } from '../ModifySearchButton/ModifySearchButton'
import { useGetDateTimeParam } from '../../hooks/useGetDateTimeParam/useGetDateTimeParam'
import { parseEpochOrIsoToUtc } from '../../utils/parseEpochOrIsoToUtc'

export const AvailabilitiesSection = ({
  restaurant,
  bookableGuid
}: {
  restaurant: RestaurantInfo
  bookableGuid?: string
}) => {
  const { language: locale } = useIntlProps()
  const { setSelectedAvailability } = useFindReserveContext()
  const removeParams = useRemoveParams()
  const getDateTimeParam = useGetDateTimeParam()
  const { partySize } = usePartySize()

  const [openReservationBookModal, setOpenReservationBookModal] =
    useState(false)

  // if we have a timeSlot param, use that to search for availabilities
  // this way 'timeSlot' and 'dateTime' can co-exist in the URL
  const dateTimeString =
    getDateTimeParam('timeSlot') || getDateTimeParam('dateTime')
  const utcDateTime = parseEpochOrIsoToUtc(dateTimeString)
  const isDateTimeValid = utcDateTime.isValid

  const startTime = utcDateTime.minus({ hours: 2, minutes: 30 })
  const endTime = utcDateTime.plus({ hours: 2, minutes: 30 })
  const startTimeString = startTime.toString().replace('Z', '+00:00')
  const endTimeString = endTime.toString().replace('Z', '+00:00')

  const {
    data: availabilities,
    isLoading,
    isError
  } = useGetAvailabilities({
    restaurantGuid: restaurant.guid,
    startTime: startTimeString,
    endTime: endTimeString,
    partySize,
    specifiedBookableGuid: bookableGuid,
    enabled: Boolean(partySize && isDateTimeValid)
  })

  const {
    serviceAreaGroupNameToGuid,
    serviceAreaGroupNameToTimes,
    serviceAreaGroupNameToTimeToDepositAmount
  } = useMemo(() => formatAvailabilities(availabilities), [availabilities])

  const handleTimeSlotSelect = useCallback(
    (
      timeSlot: string,
      deposit: Deposit | null,
      serviceAreaGroupGuid: string,
      serviceAreaGroupName: string // we should add this parameter
    ) => {
      setSelectedAvailability!({
        partySize,
        deposit,
        datetime: timeSlot,
        serviceAreaGroupName,
        serviceAreaGroupGuid
      })
      setOpenReservationBookModal(true)
      removeParams(['expired'])
    },
    [partySize, removeParams, setSelectedAvailability]
  )

  const serviceAreaGroups = useMemo(
    () =>
      formatServiceAreaGroups(
        serviceAreaGroupNameToTimes,
        serviceAreaGroupNameToGuid,
        serviceAreaGroupNameToTimeToDepositAmount
      ),
    [
      serviceAreaGroupNameToGuid,
      serviceAreaGroupNameToTimeToDepositAmount,
      serviceAreaGroupNameToTimes
    ]
  )

  useOpenBookingModalOnMount(
    serviceAreaGroups,
    handleTimeSlotSelect,
    !!availabilities
  )

  if (isError || availabilities?.length === 0) {
    return (
      <div className='flex flex-col items-center'>
        <DateTimeError subtext='No availability within 2.5 hours of the selected time.' />
        <ModifySearchButton restaurant={restaurant} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='h-16 flex justify-center items-center w-full'>
        <MerryGoRound />
      </div>
    )
  }

  return (
    <>
      {availabilities?.length !== 0 && (
        <div className='-mx-4 sm:mx-0'>
          {serviceAreaGroups.map((groupData, index, arr) => (
            <ServiceAreaGroup
              key={groupData.name}
              data={groupData}
              index={index}
              arrayLength={arr.length}
              timezone={restaurant.timezone}
              locale={locale}
              onTimeSlotSelect={handleTimeSlotSelect}
            />
          ))}
        </div>
      )}

      <CreditCardFormProvider>
        <ReservationBookModal
          restaurant={restaurant}
          onRequestClose={() => {
            setOpenReservationBookModal(false)
            removeParams(['timeSlot', 'diningArea'])
          }}
          isOpen={openReservationBookModal}
          bookableId={bookableGuid || ''}
        />
      </CreditCardFormProvider>
    </>
  )
}
