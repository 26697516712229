import { useQuery } from 'react-query'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import axios from 'axios'
import {
  DateTimeString,
  NvResponse,
  RequestName,
  RequestURL
} from '../baseModels'
import { createG2Request } from '../createRequest'
import { BookingStatus, ServiceAreaGroup } from '../bookings/getBooking'
import { Guest } from './useGetGuestProfile'
import { authHeaders } from './constants'

export interface GuestOverviewReservation {
  guid: string
  restaurantGuid: string
  restaurantShortName: string
  partySize: number
  expectedStartTime: DateTimeString
  serviceAreaGroup: ServiceAreaGroup
  requestedServiceAreaGroups: ServiceAreaGroup[]
  bookingStatus: BookingStatus
  createdByAuthenticatedUser: boolean | null
}

export const GetAllBookingsForGuestProfileQueryKey =
  'allBookingsForGuestProfile'

export const useGetAllBookingsForGuestProfile = (guest: Guest | undefined) => {
  const { getAuthenticationHeader } = useAuth()

  const getAllBookingsForGuestProfile = async (
    guestProfile: Guest | undefined
  ): Promise<GuestOverviewReservation[] | undefined> => {
    if (!guestProfile) {
      return undefined
    }

    const authHeader = await getAuthenticationHeader()
    const phoneNumber = guestProfile.contact.phone
    return axios
      .request<NvResponse<GuestOverviewReservation>>(
        createG2Request(
          RequestURL[RequestName.GET_ALL_BOOKINGS_FOR_GUEST_PROFILE],
          authHeaders(authHeader),
          undefined,
          { phoneNumber }
        )
      )
      .then((response) => response.data.results)
  }

  return useQuery(
    [GetAllBookingsForGuestProfileQueryKey, guest],
    () => getAllBookingsForGuestProfile(guest),
    { enabled: !!guest }
  )
}
