import React, { useState } from 'react'
import { Button, useButtonStyles } from '@toasttab/buffet-pui-buttons'
import { AddCircleIcon } from '@toasttab/buffet-pui-icons'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Bookable, Reservation } from '../../../api/bookings/getBooking'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { RestaurantInfo } from '../../../api/restaurant/getRestaurant'
import { getCompiledGMAdress } from '../../../utils/compileGMAdress'
import {
  getDownloadICSFile,
  getEndTime,
  getFormattedOutlookTime,
  getFormattedTime,
  getStartTime
} from './utils'
import { useLocation } from 'react-router'
import { capitalize } from '../../../utils/capitalize'
import { useRestaurantContext } from '../../../contexts/RestaurantContext'

type AddToCalendarButtonProps = {
  booking: Reservation
  restaurant: RestaurantInfo
  bookable?: Bookable | null
}

export const AddToCalendarButton = ({
  booking,
  restaurant,
  bookable
}: AddToCalendarButtonProps) => {
  const { shortUrl } = useRestaurantContext()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const hasAddToCalendarQueryParam = queryParams.get('addToCalendar') === 'true'

  const [isOpen, setIsOpen] = useState(hasAddToCalendarQueryParam)
  const isMobile = useIsMobile()
  const buttonStyles = useButtonStyles({ size: 'lg', variant: 'secondary' })

  const lineBreak = '<br/><br/>'
  const subdomain =
    window.TT_GLOBALS?.bookingApiUrl ===
    'https://ws-preprod.eng.toasttab.com/booking/'
      ? 'tables.preprod.eng'
      : 'tables'
  const bookingLink = `https://${subdomain}.toasttab.com/${shortUrl}/bookings/${booking.guid}`
  const eventTitle = bookable
    ? `${bookable.name} at ${restaurant.name}`
    : `Reservation at ${restaurant.name}`
  const eventDescription = `
  <b>${
    bookable ? 'Experience' : 'Reservation'
  } Details</b>${lineBreak}Your reservation is for ${booking.partySize} in ${
    booking.serviceAreaGroup.name
  }.

  ${
    booking.specialOccasion && booking.specialOccasion !== 'NONE'
      ? `${lineBreak}Special occasion: ${capitalize(booking.specialOccasion)}`
      : ``
  }

  ${
    booking.bookingNotes
      ? `${lineBreak}Special requests: ${booking.bookingNotes}`
      : ``
  }

  ${lineBreak}<a href="${bookingLink}">View Confirmation Details</a>

  ${bookable ? lineBreak + bookable.memo?.description : ``}

  ${lineBreak}${lineBreak}<b>Reservation Policy</b>${lineBreak}${encodeURIComponent(
    restaurant.reservationPolicy || ''
  )}

  ${lineBreak}${lineBreak}<b>About</b>${lineBreak}${encodeURIComponent(
    restaurant.description || ''
  )}

  `
    .replace(/%0A%20/g, '%0A')
    .trim()

  const startTime = getStartTime(booking.expectedStartTime, restaurant.timezone)
  const endTime = getEndTime(booking.expectedStartTime, restaurant.timezone)
  const { addressWithoutRestaurantName: streetAddress } =
    getCompiledGMAdress(restaurant)

  const outlookLink = `https://outlook.live.com/calendar/0/action/compose?allday=false&body=${eventDescription}&location=${streetAddress}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${getFormattedOutlookTime(
    startTime
  )}&enddt=${getFormattedOutlookTime(endTime)}&subject=${eventTitle}`

  return (
    <>
      <div className='flex-grow w-full'>
        <Button
          variant='text-link'
          className=''
          size='sm'
          iconLeft={<AddCircleIcon accessibility='decorative' />}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          Add to calendar
        </Button>
      </div>
      {isOpen && (
        <Modal
          isOpen
          position={isMobile ? 'pin-bottom' : undefined}
          size='lg'
          onRequestClose={() => {
            setIsOpen(false)
          }}
        >
          <Modal.Header>Add to calendar</Modal.Header>
          <Modal.Body>
            <div className='flex flex-col gap-4 py-1'>
              <a
                href={`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${getFormattedTime(
                  startTime
                )}/${getFormattedTime(endTime)}
                &text=${eventTitle}&details=${eventDescription}&location=${streetAddress}`}
                target='_blank'
                rel='noreferrer'
                className={buttonStyles}
              >
                Google Calendar
              </a>
              <Button
                variant='secondary'
                size='lg'
                onClick={() =>
                  getDownloadICSFile(
                    booking,
                    restaurant,
                    startTime,
                    endTime,
                    eventTitle,
                    streetAddress,
                    bookingLink,
                    bookable
                  )
                }
              >
                Apple Calendar
              </Button>
              <a
                href={outlookLink}
                target='_blank'
                rel='noreferrer'
                className={buttonStyles}
              >
                Outlook
              </a>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
