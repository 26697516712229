import { CalendarTodayIcon, GuestIcon } from '@toasttab/buffet-pui-icons'
import React from 'react'
import { Experience } from '../../api/experiences/useGetExperiences'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { Ticket } from './icons/Ticket'
import { Ellipse } from './icons/Ellipse'
import { getExperiencePartySizeRange } from '../../utils/getExperiencePartySizeRange'
import { getFormattedDatesActive } from '../../utils/getFormattedDatesActive'
import { useIntlProps } from 'banquet-runtime-modules'
import { Formats } from '@toasttab/buffet-pui-date-utilities'
import { ViewExperienceLink } from './ViewExperienceLink'

type FullExperienceCardProps = {
  restaurant: RestaurantInfo
  experience: Experience
}

export const FullExperienceCard = ({
  restaurant,
  experience
}: FullExperienceCardProps) => {
  const { language: locale } = useIntlProps()
  const { shortUrl } = useRestaurantContext()
  const formatCurrency = useFormatCurrency()
  const partySizeRange = getExperiencePartySizeRange(experience)
  const formattedDateRange = getFormattedDatesActive(
    locale,
    experience,
    Formats.date.medium_without_year
  )

  return (
    <div className='bg-white'>
      <div className='border-t p-4 sm:p-6'>
        <div className='flex flex-row relative justify-between'>
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex items-start justify-between gap-4'>
              <div>
                <div className='flex font-semibold type-large mb-1 line-clamp-2'>
                  {experience.name}
                </div>
                {experience.depositConfig && (
                  <div className='flex items-center'>
                    <Ticket />
                    <span className='pb-0.5 pl-1 font-semibold type-subhead'>
                      From{' '}
                      {formatCurrency(experience.depositConfig.minimumDeposit)}{' '}
                      deposit /
                      {experience.depositConfig.strategy === 'PARTYSIZE'
                        ? ' person'
                        : ' booking'}
                    </span>
                  </div>
                )}
                {(experience.datesActive.length > 0 ||
                  experience.shifts.length > 0) && (
                  <div className='flex flex-wrap items-center'>
                    <CalendarTodayIcon
                      size='xs'
                      accessibility='decorative'
                      className='pb-0.5'
                    />
                    <span className='pl-1 font-semibold type-subhead'>
                      {formattedDateRange}
                    </span>

                    {experience.datesActive.length > 0 &&
                      experience.shifts.length > 0 && (
                        <div className='px-2'>
                          <Ellipse />
                        </div>
                      )}
                    {experience.shifts.length > 0 && (
                      <div className='flex flex-row items-center'>
                        <GuestIcon
                          size='xs'
                          accessibility='decorative'
                          className='pb-0.5'
                        />
                        <span className='pl-1 font-semibold type-subhead'>
                          Party size {partySizeRange}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='justify-self-end'>
                <ViewExperienceLink experience={experience} />
              </div>
            </div>
            {experience.memo?.description && (
              <p className='text-secondary line-clamp-4'>
                {experience.memo.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
