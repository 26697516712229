import axios from 'axios'
import {
  DateTimeString,
  NvResponse,
  RequestName,
  RequestURL
} from '../baseModels'
import { createG2Request } from '../createRequest'
import { useQuery } from 'react-query'
import { useBanquetProps } from 'banquet-runtime-modules'

export interface Memo {
  name: string
  description: string
}

interface ExperienceHours {
  sunday: {
    enabled: boolean
    start: string
    end: string
  }
  monday: {
    enabled: boolean
    start: string
    end: string
  }
  tuesday: {
    enabled: boolean
    start: string
    end: string
  }
  wednesday: {
    enabled: boolean
    start: string
    end: string
  }
  thursday: {
    enabled: boolean
    start: string
    end: string
  }
  friday: {
    enabled: boolean
    start: string
    end: string
  }
  saturday: {
    enabled: boolean
    start: string
    end: string
  }
}

interface ExperienceShift {
  guid: string
  name: string
  maxPartySize: number
  minPartySize: number
  timeSlotSize: number
  hours: ExperienceHours
  bookingMinMinutesInAdvance: number
}

interface PublicExperienceDepositConfig {
  strategy: 'PARTYSIZE' | 'BOOKING'
  minimumDeposit: number
}

export interface Experience {
  guid: string
  name: string
  datesActive: DateTimeString[]
  alwaysAvailable: boolean
  memo: Memo | null
  slug: string | null
  shifts: ExperienceShift[]
  depositConfig: PublicExperienceDepositConfig | null
}

export const GetUpcomingExperiencesQueryKey = 'upcomingExperiences'

const getExperiences = async (
  restaurantGuid: string,
  startDate: string
): Promise<Experience[]> =>
  axios
    .request<NvResponse<Experience>>(
      createG2Request(
        RequestURL[RequestName.GET_UPCOMING_EXPERIENCES],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        undefined,
        { startDate }
      )
    )
    .then((response) => response.data.results)

export const useGetExperiences = (
  restaurantGuid: string,
  startDate: string
) => {
  const { featureFlags } = useBanquetProps()
  const isExperiencesEnabled = Boolean(featureFlags?.['nv1-experiences'])
  return useQuery(
    [GetUpcomingExperiencesQueryKey, restaurantGuid, startDate],
    () => getExperiences(restaurantGuid, startDate),
    {
      enabled: Boolean(isExperiencesEnabled && restaurantGuid && startDate)
    }
  )
}
